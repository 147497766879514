import React from 'react'
import Images from '../../../assets/Images'
import { useNavigate } from 'react-router'
import atoms from '../../atoms'
import { numberWithCommas } from '../../../constants/utils'
import constant from '../../../constants/constant'
const { CoverImage } = Images
const { AvailableBadge, CustomImages } = atoms
const Index = ({ productData }: any) => {
    const navigate = useNavigate()
    const badgeTitle = productData?.available_at_store ? "Available At Store" : `Available in:${ productData?.transit_days}`
    return (
        <div className="col-6 col-md-4 mt-2" onClick={() => navigate(`/product/details/${productData?.id}`)}>
            <div className='product-card position-relative'>
                {(productData?.available_at_store || productData?.transit_days !== constant.Product_Transit_Days_Undefined ) && <div className="product-card-badge">
                    <AvailableBadge title={badgeTitle} isAvailable={!productData?.available_at_store}/>
                </div>}
                {/* <div className='product-img' style={{ backgroundImage: `url(${productData?.images[0] || CoverImage})` }}>
                    
                </div> */}
                <CustomImages className='product-img' imageHeight={window.innerWidth < 876 ? '60px' : '200px'} src={productData?.images[0] || CoverImage} />

                <div className='d-block d-lg-flex flex-wrap justify-content-between align-items-md-center mt-lg-4 mt-1'>
                    <h2 className='mb-0 fs-md-16 fs-14 fw-700'>{productData?.brand?.brand_name}</h2>
                    <div className='justify-content-between'>
                        <h2 className='mb-0 mt-1 mt-md-0 fs-md-14 fs-12 fw-600'>₹{numberWithCommas(parseFloat(productData?.product_srp))}(SRP)</h2>
                        <h2 className='mb-0 d-lg-none mt-1 mt-md-0 fs-md-14 fs-12 fw-600'>₹{numberWithCommas(parseFloat(productData?.brand_cost_amount))}(L.C)</h2>
                    </div>
                </div>
                <div className='d-block d-md-flex flex-wrap justify-content-between align-items-md-center'>
                    {!productData?.is_contact_lens && <h2 className=' fs-12 fw-500 my-2'>Model No:<span> {productData?.modal_number}</span></h2>}
                    {productData?.is_contact_lens && <h2 className=' fs-12 fw-500 my-2'>Color:<span> {productData?.color.color_name}</span></h2>}
                    <h2 className='d-none d-lg-flex mb-0 mt-2 mt-md-0 fs-14 fw-600'>₹{numberWithCommas(parseFloat(productData?.brand_cost_amount))}(L.C)</h2>
                </div>
                {/* <h2 className=' fs-12 fw-500 my-2'>Model No:<span> {productData?.modal_number} </span></h2> */}

                {!productData?.is_contact_lens && <div className='d-none d-lg-flex justify-content-between align-items-center'>
                    <h2 className='mb-0 fs-12 fw-500'>Size/Power: <span>{productData?.glass_size}MM</span></h2>
                    <h2 className='mb-0 fs-12 fw-500'>Color: <span>{productData?.brand_color_code}</span></h2>
                </div>}
            </div>
        </div>
    )
}

export default Index