import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import { HeaderHeading } from '../../../Context'
import organisms from "../../../components/organisms";
import {Default_Page_Limit} from "../../../constants/defaultValues"
import service from "../../../api/services";

const { Staff } = organisms
const Index = () => {
  const [initialLoading, setInitialLoading]=useState(false)
    const [staffData, setStaffData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const { setHeader }: any = useContext(HeaderHeading);

    useEffect(() => {
        setHeader("Staff List")
        if(initialLoading){
            getStaffData()
        }
        setInitialLoading(true)
    }, [currentPage, search])

    useEffect(() => {
        setCurrentPage(1)
        if (currentPage == 1) {
            getStaffData()
        }
    }, [limitPerPage])


    const getStaffData = async () => {
        try {
            let response = await service.staff.view({ "page": currentPage, "search": search, "limit": limitPerPage })
            if (response.status === 200) {
                setStaffData(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const _handleDelete=async(id:string)=>{
        try {
            const response = await service.staff.delete(id)
            if(response.status==200){
                getStaffData()
            }
        } catch (error) {

        }
    }
    return (
        <Wrapper>
            <Staff 
             setLimitPerPage={setLimitPerPage}
             setCurrentPage={setCurrentPage}
             _handleDelete={(id:string)=>_handleDelete(id)}
             setSearch={setSearch}
             staffData={staffData}
             currentPage={currentPage}
             limitPerPage={limitPerPage}
            />
        </Wrapper>
    )

}

export default Index