import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import atoms from "../../atoms";
import molecules from "../../molecules";
import { validationRulesSubAdmin } from "./Validation";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import service from "../../../api/services";
const { Button, Input, Select } = atoms;
const { TopBar, CreateModal, CheckboxDropdown } = molecules;

const AddSubAdmin = ({
    setStaff,
    staff,
    setButtonClick,
    handleChangePassword,
    storeList,
    structuredPermission,
    defaultPermission,
}: any) => {
    const [errors, setErrors] = useState<any>({});
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [password, setPassword] = useState({
        newPassword: "",
        conformPassword: "",
        showPassword: false,
        showConformPassword: false,
    });
    let params = useParams();
    let staffEditID = params.id || "";
    const handleChange = (name: string, value: any) => {
        setStaff((prevState: any) => ({ ...prevState, [name]: value }));
        if (name !== "login_id") {
            setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
        }
    };
    const validate = () => {
        let newErrors: any = {};
        for (const field in validationRulesSubAdmin) {
            if (validationRulesSubAdmin.hasOwnProperty(field)) {
                if (validationRulesSubAdmin[field]?.required && !staff[field]) {
                    newErrors[field] = "This field is required.";
                } else if (
                    validationRulesSubAdmin[field]?.label?.required &&
                    !staff[field]?.label
                ) {
                    newErrors[field] = "Please Select field";
                }
                if (
                    validationRulesSubAdmin[field].pattern &&
                    !validationRulesSubAdmin[field].pattern.test(staff[field])
                ) {
                    newErrors[field] = "Invalid format";
                }
                if (
                    validationRulesSubAdmin[field].minLength &&
                    staff[field].length < validationRulesSubAdmin[field].minLength
                ) {
                    newErrors[
                        field
                    ] = `Minimum length is ${validationRulesSubAdmin[field].minLength}`;
                }

                if (
                    validationRulesSubAdmin[field].maxLength &&
                    staff[field].length > validationRulesSubAdmin[field].maxLength
                ) {
                    newErrors[
                        field
                    ] = `Maximum length is ${validationRulesSubAdmin[field].maxLength}`;
                }
                if (
                    validationRulesSubAdmin[field].requiredLength &&
                    staff[field].length !== validationRulesSubAdmin[field].requiredLength
                ) {
                    newErrors[
                        field
                    ] = `Required length is ${validationRulesSubAdmin[field].requiredLength}`;
                }
                if (!staffEditID && !staff.password) {
                    newErrors.password = "This field is required.";
                }
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = () => {
        const isValid = validate();
        if (isValid) {
            setButtonClick(true);
        }
    };
    const changePassword = () => {
        handleChangePassword(password?.newPassword);
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setPassword({ ...password, newPassword: "", conformPassword: "" });
        setCreateModal(!createModal);
    };
    const _handleSearchUserID = async (userID: string) => {
        handleChange("login_id", userID.trim());
        try {
            const response = await service.store.searchUser(userID,{for_subadmin: true});
            if (response.status === 200) {
                const login_id = response.data.data.login_id;
                if (login_id) {
                    setErrors((prevErrors: any) => ({
                        ...prevErrors,
                        login_id: "Existing User",
                    }));
                    handleChange("login_id", userID.trim());
                }
            }
        } catch (error: any) {
            if (error.response.status == 404) {
                setErrors({ ...errors, login_id: "" });
            }
        }
    };

    const _handleCheckboxChange = (codeArr: []) => {
        setStaff((prevState: any) => ({ ...prevState, permissions: codeArr }));
    };

    return (
        <div>
            <div className="row align-items-center mb-4">
                <div className="col-md-6 ">
                    <TopBar heading={staffEditID ? "Update" : "Add"} />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-4 mb-md-0 mb-3">
                    {/* <Input label={"Usernamexxxx"} type={'text'} placeholder={"Enter UserName"} errors={errors.login_id}
                        value={staff.login_id}
                        onChange={(e: any) => { handleChange("login_id", e.target.value.trim()) }}
                    /> */}
                    <div className="position-relative">
                        <label className="inputs-label mb-2 fs-16 fw-600  secondary-color">
                            User ID
                        </label>
                        <input
                            type="text"
                            className={"inputs form-control w-100"}
                            placeholder={"Enter User ID"}
                            value={staff.login_id}
                            onChange={(e: any) => _handleSearchUserID(e.target.value)}
                        // disabled={params?.id ? true : false}
                        />
                    </div>
                    {errors.login_id && (
                        <span className="error-text">{errors.login_id}</span>
                    )}
                </div>

                <div className="col-md-4 mb-md-0 mb-3">
                    <Input
                        label={"User Name"}
                        type={"text"}
                        placeholder={"Enter Person Name"}
                        errors={errors.username}
                        value={staff.username}
                        onChange={(e: any) => {
                            handleChange("username", e.target.value);
                        }}
                    />
                </div>

                <div className="col-md-4 mb-md-0 mb-3">
                    <Input
                        label={"Contact Number"}
                        type={"number"}
                        placeholder={"Enter Contact Number"}
                        errors={errors.mobile}
                        value={staff.mobile}
                        onChange={(e: any) => {
                            handleChange("mobile", e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-4 mb-md-0 mb-3">
                    <Input
                        label={"Email Address"}
                        type={"email"}
                        placeholder={"Enter Email Address"}
                        errors={errors.email}
                        value={staff.email}
                        onChange={(e: any) => {
                            handleChange("email", e.target.value);
                        }}
                    />
                </div>
                <div className="col-md-4 mb-md-0 mb-3 position-relative">
                    <div className="d-flex justify-content-between">
                        <label
                            className="inputs-label mb-2 fs-16 fw-600  secondary-color"
                            htmlFor="password"
                        >{`${staffEditID ? "" : "Enter"} Password`}</label>
                        {staffEditID && (
                            <a
                                className="fs-14"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setCreateModal(!createModal);
                                }}
                            >
                                Change Password
                            </a>
                        )}
                    </div>
                    <Input
                        // label={"Change Password"}
                        type={`${showPassword ? "text" : "password"}`}
                        placeholder={"Enter Password"}
                        errors={errors.password}
                        value={staffEditID ? "********" : staff.password}
                        disabled={staffEditID && true}
                        onChange={(e: any) => {
                            handleChange("password", e.target.value);
                        }}
                    />
                    {!staffEditID && (
                        <span
                            className="position-absolute cursor-pointer"
                            style={{ top: "49%", right: "10%", fontSize: "20px" }}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </span>
                    )}
                </div>
                <div className="col-md-4 mb-md-0 mb-3">
                    <Select
                        label={"Store "}
                        placeholder={"Select Store"}
                        className={"w-100"}
                        errors={errors.role}
                        options={storeList}
                        value={staff.store_id}
                        isMulti={true}
                        onChange={(e: any) => {
                            handleChange("store_id", e);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-md-4">
                <div className="col-md-6">
                    <label className="inputs-label mb-2 fs-16 fw-600  secondary-color">
                        User Permission
                    </label>
                    <div className="member-permission">
                        <CheckboxDropdown
                            structuredPermission={structuredPermission}
                            handleCheckboxChange={(codeArr: []) =>
                                _handleCheckboxChange(codeArr)
                            }
                            defaultPermission={defaultPermission}
                        />
                    </div>
                </div>

                <div className="col-md-4 mt-2 mt-md-0">
                    {/* <Select label={"Role "} placeholder={"Select Role"} className={"w-100"} errors={errors.role}
                        // options={City_List}
                        value={staff.role}
                        onChange={(e: any) => { handleChange("role", e) }}
                    /> */}
                </div>
                <div className="d-none d-md-block col-md-4"></div>
            </div>

            <div className="justify-content-center d-flex mt-4">
                <Button
                    name={staffEditID ? " Update Subadmin" : "Add Subadmin"}
                    className={"w-md-25"}
                    onClick={() => handleSubmit()}
                />
            </div>

            {createModal && (
                <CreateModal
                    show={createModal}
                    header={" New Password"}
                    handleSubmit={() => changePassword()}
                    buttonDisable={
                        !(password?.conformPassword == password?.newPassword) ||
                        !password.newPassword
                    }
                    submitBtnText={"Change Password"}
                    onHide={() => handleCloseModal()}
                >
                    <div>
                        <form>
                            <div className=" mt-25">
                                <div className="mt-4">
                                    <Input
                                        type={`${password?.showPassword ? "text" : "password"}`}
                                        className="form-control"
                                        placeholder="Enter Password"
                                        onChange={(e: any) =>
                                            setPassword({ ...password, newPassword: e.target.value })
                                        }
                                        // defaultValue={storeDetails.admin_change_password}
                                        label="New Password"
                                    />
                                    <span
                                        className="position-absolute cursor-pointer"
                                        style={{ top: "33%", right: "10%", fontSize: "20px" }}
                                        onClick={() =>
                                            setPassword({
                                                ...password,
                                                showPassword: !password?.showPassword,
                                            })
                                        }
                                    >
                                        {password?.showPassword ? (
                                            <AiOutlineEyeInvisible />
                                        ) : (
                                            <AiOutlineEye />
                                        )}
                                    </span>
                                </div>
                                <div className="mt-4">
                                    <Input
                                        type={`${password?.showConformPassword ? "text" : "password"
                                            }`}
                                        className="form-control"
                                        placeholder="Enter Password"
                                        onChange={(e: any) =>
                                            setPassword({
                                                ...password,
                                                conformPassword: e.target.value,
                                            })
                                        }
                                        // defaultValue={storeDetails.admin_change_password}
                                        label="Confirm Password"
                                    />
                                    <span
                                        className="position-absolute cursor-pointer"
                                        style={{ top: "75%", right: "10%", fontSize: "20px" }}
                                        onClick={() =>
                                            setPassword({
                                                ...password,
                                                showConformPassword: !password?.showConformPassword,
                                            })
                                        }
                                    >
                                        {password?.showConformPassword ? (
                                            <AiOutlineEyeInvisible />
                                        ) : (
                                            <AiOutlineEye />
                                        )}
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </CreateModal>
            )}
        </div>
    );
};
export default AddSubAdmin;
