import React, { useState } from 'react'
import atoms from '../../atoms'
import { useToasts } from 'react-toast-notifications'
const { TextArea, Button } = atoms
const GreetingTab = ({ message, setMessage, shootMessage }: any) => {
  return (
    <div className=''>
      <div className=''>
        <TextArea label={'Greeting Message'} placeholder='message' type="text" rows={3} onChange={(e: any) => setMessage(e.target.value)} />
      </div>
      <div className='d-flex justify-content-center mt-5'>
        <Button className="fs-16 fw-500 approve-btn w-25"
          isDisabled={!message}
          onClick={() => shootMessage()}
        >Shoot</Button>
      </div>
    </div>
  )
}

export default GreetingTab