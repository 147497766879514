import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import organisms from '../../../../components/organisms'
import { Default_Page_Limit } from "../../../../constants/defaultValues"
import { HeaderHeading } from '../../../../Context'


const { TransferRequested } = organisms

const Index = () => {
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [transferRequestedList, setTransferRequestedList] = useState<any>();
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();
  const { setHeader }: any = useContext(HeaderHeading);
  const [isContactLensProduct, setIsContactLensProduct] = useState<boolean>(false);

  const getTransferRequestedList = async () => {
    try {
      const response = await service.procurementService.transferRequestedList({ "page": currentPage, "search": search, "limit": limitPerPage, "status": '',"is_contact_lens":isContactLensProduct })
      if (response.status === 200) {
        setTransferRequestedList(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const cancelButton = async (id: string, data: string) => {
    const payload = {
      "cancel_reason": data
    }
    try {
      const response = await service.procurementService.transferRequestedListItemCancel(id, payload)
      if (response.status == 200) {
        getTransferRequestedList()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setHeader("Transfer Requests - Requested")
    if (initialLoading) {
      getTransferRequestedList()
    }
    setInitialLoading(true)
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1) {
      getTransferRequestedList()
    }
  }, [limitPerPage, search,isContactLensProduct])

  return (
    <Wrapper>
      <TransferRequested
        transferRequestedList={transferRequestedList}
        setSearch={setSearch}
        cancelButton={(id: string, data: string) => cancelButton(id, data)}
        limit={limitPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLimitPerPage={setLimitPerPage} 
        isContactLensProduct={isContactLensProduct}
        setIsContactLensProduct={(value:boolean)=>setIsContactLensProduct(value)}/>
    </Wrapper>
  )
}

export default Index