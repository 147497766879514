import React, { useContext, useEffect, useState } from "react";
import organisms from "../../../components/organisms";
import molecules from "../../../components/molecules"
import { HeaderHeading } from "../../../Context";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import service from "../../../api/services";
import atoms from "../../../components/atoms";
import { useToasts } from "react-toast-notifications";

const { Setting } = organisms;
const { ConfirmationModal, CreateModal } = molecules
const { Input } = atoms


const Index = () => {
  const { addToast } = useToasts();
  const [printPrescription, setPrintPrescription] = useState(false);
  const [defaultDays, setDefaultDays] = useState<number>(0);
  const [inputDays, setInputDays] = useState<number>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDaysModal, setShowDaysModal] = useState<boolean>(false);
  const { setHeader }: any = useContext(HeaderHeading);


  const getSetting = async () => {
    try {
      let response = await service?.SettingService?.getList()
      setPrintPrescription(response?.data?.data?.print_prescription)
      setDefaultDays(response?.data?.data?.default_order_ready_days)
      setInputDays(response?.data?.data?.default_order_ready_days)
    } catch (error) {
      console.log(error)
    }
  }

  const updateSetting = async () => {
    let payload = {
      default_order_ready_days: inputDays ? Number(inputDays) : '',
      print_prescription: !printPrescription
    }
    try {
      let response = await service?.SettingService?.updateSetting(payload)
      setPrintPrescription(response?.data?.data?.print_prescription)
      setDefaultDays(response?.data?.data?.default_order_ready_days)
      setInputDays(response?.data?.data?.default_order_ready_days)
      if (response.status === 200 || response.status === 201) {
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        getSetting()
        setShowModal(false)
        setShowDaysModal(false)
      }
    } catch (error: any) {
      console.log(error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }


  useEffect(() => {
    setHeader("Settings")
    getSetting()
  }, [])

  const updateDays = async () => {
    let payload = {
      default_order_ready_days: inputDays ? Number(inputDays) : '',
      print_prescription: printPrescription
    }
    try {
      let response = await service?.SettingService?.updateSetting(payload)
      setPrintPrescription(response?.data?.data?.print_prescription)
      setDefaultDays(response?.data?.data?.default_order_ready_days)
      setInputDays(response?.data?.data?.default_order_ready_days)
      if (response.status === 200 || response.status === 201) {
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        getSetting()
        setShowModal(false)
        setShowDaysModal(false)
      }
    } catch (error: any) {
      console.log(error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: any = e.target.value;
    if (value === "") {
      setInputDays(value);
      return;
    }
    const numericValue = parseInt(value, 10);
    if (numericValue < 4) {
      setInputDays(value);
    }
  };

  return (
    <Wrapper>
      <Setting
        printPrescription={printPrescription}
        setShowModal={setShowModal}
        defaultDays={defaultDays}
        setShowDaysModal={(type) => { setShowDaysModal(type); setInputDays(defaultDays) }}
      />
      {showModal && <ConfirmationModal
        show={showModal}
        handleConfirmItem={()=>updateSetting()}
        onHide={() => setShowModal(!showModal)}
        message={`${!printPrescription ? 'display' : 'hide'} the lens prescription from the invoice`} />}

      <CreateModal
        show={showDaysModal}
        header={'Enter Days'}
        submitBtnText={'Update'}
        handleSubmit={() => updateDays()}
        onHide={() => setShowDaysModal(false)}
      >
        <Input type={'number'} name={'Days'} value={inputDays}
          onChange={(e: any) => handleInputChange(e)}
        />
      </CreateModal>
    </Wrapper>
  );
};

export default Index;
