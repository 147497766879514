export const AllPermissions = {

    // Un_Recognize_Tab section
    store_Un_Recognize_Tab: "D6Z5A",

    // Un_Recognize Product Tab
    store_Un_Brand: "L6B2H",

    // Un_Recognize Product Tab
    store_Un_Product: "A1B2C",
    store_Un_Sub_Product: "B2C3D",
    store_Un_Bulk_Product: "C3D4E",

    // Un_Recognize Inventory Tab
    store_Un_Inventory: "D4E5F",
    store_Un_Pending_Inventory: "E5F6G",
    store_Un_Approve_Inventory: "F6G7H",
    store_Un_Search: "G7H8I",

    // Un_Recognize Barcode TabS9T0U
    store_Un_Barcode: "H8I9J",
    store_Un_Pending_Barcode: "I9J0K",
    store_Un_Approve_Barcode: "J0K1L",
    store_Un_Price_Updated_Barcode: "K1L2M",

    // Recognize Tab section
    store_Recognize_Tab: "L2M3N",

    // Recognize Product Tab
    store_Brand: "L6Z9H",

    // Recognize Product Tab
    store_Product: "M3N4O",

    // Recognize Inventory Tab
    store_Inventory: "N4O5P",
    store_Pending_Inventory: "O5P6Q",
    store_Approve_Inventory: "P6Q7R",
    store_Search: "Q7R8S",

    // Recognize Barcode Tab
    store_Barcode: "R8S9T",
    store_Pending_Barcode: "S9T0U",
    store_Approve_Barcode: "T0U1V",
    store_Price_Updated_Barcode: "U1V2W",

    // Procurement section
    store_Procurement_Tab: "V2W3X",
    store_Product_List: "W3X4Y",
    store_Order_Cart: "X4Y5Z",
    store_Order_History: "Y5Z6A",
    store_Order_Received: "Z6A7B",
    store_Order_Cancelled: "A7B8C",

    // Transfer Requests
    store_Transfer_Requests_Tab: "B8C9D",
    store_Requested: "C9D0E",
    store_Move_Out: "D0E1F",
    store_Cancelled: "E1F2G",

    // Staff section
    store_Staff_Tab: "F2G3H",

    // Sub Admin Section
    store_Sub_Admin_Tab: "G3H4I",

    // Report Section
    store_Report_Tab: "H4I5J",
    store_Sales_Report: "I5J6K",
    store_Sales_Return_Report: "J6K7L",
    store_Inventory_Report: "K7L8M",
    store_Daily_Activity_Report: "L8M9N",
    store_GST_OutPut_Report: "M9N0O",
    store_Inventory_Addition_Report: "N0O1P",

    // Setting Section
    store_Setting_Tab: "O1P2Q",

    // Accounts Section
    store_Accounts_Tab: "P2Q3R",
    store_Transaction_Tab: "P1K3R",
    store_General_Accounts_Tab: "L9JJ6"

}
