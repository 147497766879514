import atoms from "../../../atoms";
import moment from "moment";
import {
    numberWithCommas,
} from "../../../../constants/utils";
import { IndexProps, Inventory } from "./interface";
import { useNavigate } from "react-router";
import { AiOutlineEye } from "react-icons/ai";
import constant from "../../../../constants/constant";
import molecules from "../../../molecules";
import { useEffect, useRef, useState } from "react";
import service from "../../../../api/services";

const { Pagination, Search, Table, Input, Button } = atoms;
const { LightBox } = molecules;

const Index = ({
    approveInventoryData,
    setSearch,
    currentPage,
    setCurrentPage,
    setLimitPerPage,
    limit,
    isRecognized,
    setShowSidebarModal,
    showSidebarModal
}: IndexProps) => {
    const inputRef: any = useRef()
    const navigate = useNavigate();
    const [isViewData, setIsViewData] = useState<any>({})
    // const [showSidebarModal, setShowSidebarModal] = useState(false)

    const columns = [
        { name: 'Stock Addition ID', selector: (row: Inventory) => row.invoice_id_display, sortable: true },
        { name: 'Supplier Details', selector: (row: Inventory) => row?.local_supplier_name || row.supplier?.supplier_name  },
        { name: 'Supplier Invoice No.', selector: (row: Inventory) => row?.supplier_invoice_number },
        { name: 'Supplier Invoice Date', selector: (row: Inventory) => moment(row?.supplier_invoice_date).format('DD/MM/YYYY'), },
        { name: 'Invoice Value (₹)', selector: (row: Inventory) => numberWithCommas(parseFloat(row?.total_amount?.toFixed(2))) },
        {
            name: 'Verification Status', selector: (row: any) => row.verification_status === constant.VERIFICATION_STATUS.PENDING ?
                (<div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : row?.verification_status === constant.VERIFICATION_STATUS.VERIFIED ?
                    (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Approved"}</p></div>) : row?.verification_status === constant.VERIFICATION_STATUS.REWORK ?
                        (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rework"}</p></div>) : '',
        },
        { name: 'Action', selector: (row: any) => row.action, minWidth: "120px" },
    ]

    const getViewData = async (id: string) => {
        try {
            let response = await service.inventory.getDetailById(id)
            setIsViewData(response.data.data)
            setShowSidebarModal(true)
        } catch (error: any) {
            console.log(error)
            // addToast(error?.response?.data?.message, {
            //     appearance: 'error',
            //     autoDismiss: true,
            //     autoDismissTimeout: 3000,
            // });
        }
    }
    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.value = "";
        }
    }, [isRecognized]);
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-4 mb-lg-0 mb-3">
                    <Search
                        refs={inputRef}
                        searchCallBack={(e: string) => {
                            setSearch(e);
                        }}
                        className="w-100"
                    />
                </div>
                <div className="col-12 col-md-8 gap-2 d-lg-flex justify-content-lg-end px-lg-0">
                    <div className="d-flex justify-content-lg-center justify-content-end">
                        <Button onClick={() => navigate(`/inventory/add?isRecognized=${isRecognized}`)}>
                            Add Inventory
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    AdditionalClassName="table-height-50"
                    data={approveInventoryData?.data?.map((item: any) => {
                        return {
                            ...item,
                            action: (
                                <>
                                    <div style={{ overflow: "unset", textOverflow: "unset" }}>
                                        <Button
                                            className="table-edit-buttons fs-14 fw-500"
                                            onClick={() => {
                                                getViewData(item.id);
                                            }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <AiOutlineEye className="fs-20" />
                                                <span className="ms-2 mb-0">View</span>
                                            </div>
                                        </Button>
                                    </div>
                                </>
                            ),
                        };
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={approveInventoryData?.count}
                    currentPage={currentPage}
                    totalPages={approveInventoryData?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
            {showSidebarModal && <div>
                <LightBox show={showSidebarModal} onHide={() => setShowSidebarModal(false)}
                    isViewData={isViewData}
                />
            </div>}
        </>
    );
};

export default Index;
