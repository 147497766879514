import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import constant from "../../../../constants/constant"
import { Default_Page_Limit } from "../../../../constants/defaultValues"
import organisms from '../../../../components/organisms'
import { HeaderHeading } from '../../../../Context'

const { TransferRequestMoveOut } = organisms

const Index = () => {
  const [initialLoading, setInitialLoading] = useState(false)
  const [transferRequestMoveOutList, setTransferRequestMoveOutList] = useState<any>();
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();
  const { setHeader }: any = useContext(HeaderHeading);
  const [createdTo, setCreatedTo] = useState()
  const [createdFrom, setCreatedFrom] = useState()
  const [isContactLensProduct, setIsContactLensProduct] =
  useState<boolean>(false);


  const getTransferRequestMoveOutList = async () => {
    try {
      const response = await service.procurementService.transferRequestMoveOutList({
        "page": currentPage,
        "search": search,
        "limit": limitPerPage,
        "status": constant.PROCUREMENT_ITEM_STATUS.DELIVERED,
        "created_from": createdFrom,
        "created_to": createdTo,
        "is_contact_lens":isContactLensProduct
      })
      if (response.status === 200) {
        setTransferRequestMoveOutList(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setHeader("Transfer Requests - Moved Out")
    if (initialLoading) {
      getTransferRequestMoveOutList()
    }
    setInitialLoading(true)
  }, [ currentPage])

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1) {
      getTransferRequestMoveOutList()
    }
  }, [limitPerPage,isContactLensProduct,search])

  useEffect(() => {
    if (createdTo && createdFrom) {
      getTransferRequestMoveOutList()
    }
  }, [createdFrom, createdTo])

  return (
    <Wrapper>
      <TransferRequestMoveOut
        transferRequestMoveOutList={transferRequestMoveOutList}
        setSearch={setSearch}
        limit={limitPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLimitPerPage={setLimitPerPage}
        setCreatedTo={setCreatedTo}
        setCreatedFrom={setCreatedFrom}
        isContactLensProduct={isContactLensProduct}
        setIsContactLensProduct={(value:boolean)=>setIsContactLensProduct(value)}
      />
    </Wrapper>
  )
}

export default Index