import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import organisms from '../../../components/organisms'
import { useToasts } from 'react-toast-notifications'
import service from '../../../api/services'
import { HeaderHeading } from '../../../Context'

const { GreetingTab } = organisms
const Greeting = () => {
  const { addToast } = useToasts();
  const { setHeader }: any = useContext(HeaderHeading);
  const [message, setMessage] = useState('')

  const shootMessage = async () => {
    try {
      let response = await service?.SettingService?.message({ greet_message: message })
      if (response.status === 200 || response.status === 201) {
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    } catch (error: any) {
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }
  useEffect(() => {
    setHeader("Settings-Greeting Message")
  }, [])

  return (
    <Wrapper>
      <GreetingTab shootMessage={shootMessage} message={message} setMessage={setMessage} />
    </Wrapper>
  )
}

export default Greeting