export const validationRules: any = {
    modal_number: {
        required: true,
    },
    brand_color_code: {
        required: true,
    },
    // brand_color_name: {
    //     required: true,
    // },

    glass_size: {
        required: true,
    },
 
    product_type_id: {
        "label": {
            required: true,
        },
    },
    brand_id: {
        "label": {
            required: true,
        },
    },
  
    isPolarised: {
        "label": {
            required: true,
        },
    },
    // images: { required: true },

};

export const validationRulesContactLens: any = {
    images: {
        required: true,
    },
    product_srp: {
        required: true,
    },
    lens_name: {
        required: true,
    },
    color_id: {
        required: true,
    },
   
    pack_of_quantity: {
        required: true,
    },
 
    product_type_id: {
        "label": {
            required: true,
        },
    },
    brand_id: {
        "label": {
            required: true,
        },
    },

    lens_type: {
        "label": {
            required: true,
        },
    },
    period_use: {
        "label": {
            required: true,
        },
    },
};