import React, { useState } from 'react'
import atoms from '../../../atoms'
import constant from '../../../../constants/constant';
import molecules from '../../../molecules';
import moment from 'moment'
import { IsShowModifiedTableProps, MyComponentProps, ProcurementItemsProps, procurementDetail } from "./interface"
import { RxCrossCircled } from "react-icons/rx";
import { numberWithCommas } from '../../../../constants/utils'

const { ConfirmationModal, TopBar,TableItemStatus } = molecules
const { Button, Table } = atoms

const Index: React.FC<MyComponentProps> = ({ orderHistoryItems, cancelButton }) => {
    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const [procurementItemId, setProcurementItemId] = useState<string>("")

    const columns = [
        { name: 'Order ID/Warranty Claim ID', selector: (row: procurementDetail) => row?.order?.order_id_display ? row?.order?.order_id_display : (row?.warranty_claimed_product?.claim_id || "-"), minWidth: "120px" },
        { name: 'Product ID', selector: (row: procurementDetail) => row?.product?.product_id_display, minWidth: "120px" },
        { name: 'Product Type', selector: (row: procurementDetail) => row?.product?.product_type?.name, minWidth: "120px" },
        { name: 'Cover Photo', selector: (row: procurementDetail) => <img src={row?.product?.images[0] && row?.product?.images[0]} className='w-50 rounded object-contain' /> },
        { name: 'Brand', selector: (row: procurementDetail) => row?.product?.brand?.brand_name || '-', minWidth: "120px" },
        { name: 'Model No', selector: (row: procurementDetail) => row?.product?.modal_number || '-', minWidth: "120px" },
        { name: 'Brand Color Code', selector: (row: procurementDetail) => row?.product?.brand_color_code || '-', minWidth: "120px" },
        { name: 'Glass Size/Power', selector: (row: procurementDetail) => row?.product?.glass_size || '-', minWidth: "120px" },
        { name: 'SRP', selector: (row: procurementDetail) => `₹${numberWithCommas(parseFloat(Number(row?.product?.product_srp)?.toFixed(2)))}` || '-', minWidth: "120px" },
        { name: 'Landing Cost', selector: (row: procurementDetail) => `₹${numberWithCommas(parseFloat(Number(row?.landing_cost)?.toFixed(2)))}` || '-', minWidth: "120px" },
        { name: 'Status', selector: (row: any) => row?.status || '-', minWidth: "120px" },
        { name: 'Action', selector: (row: any) => row?.action || '-', minWidth: "150px" },
    ]

    const _handleDeletePopup = (id: string) => {
        setProcurementItemId(id)
        setDeletePopup(!deletePopup)
    }

    const modifiedTableData = ({ data, isContactLens }: IsShowModifiedTableProps) => {
        let filterCondition = (items: ProcurementItemsProps): boolean => items?.product?.is_contact_lens === isContactLens
        return data?.filter(filterCondition)
    }

    const isShowModifiedTable = ({ data, isContactLens }: IsShowModifiedTableProps): boolean => {
        return data && modifiedTableData({ data: data, isContactLens: isContactLens }).length > 0
    }
    const tableActionSection = ({
        item,
    }: {
        item: { status: number; id: string };
    }) => {
        return (
            <div style={{ overflow: "unset", textOverflow: "unset" }}>
                {!(
                    item.status === constant.PROCUREMENT_ITEM_STATUS.CANCEL ||
                    item.status === constant.PROCUREMENT_ITEM_STATUS.DELIVERED
                ) && (
                        <Button
                            className="table-edit-button fs-14 fw-400 p-2"
                            onClick={() => _handleDeletePopup(item?.id)}
                        >
                            <div className="d-flex align-items-center">
                                <RxCrossCircled className="fs-14 fw-400" />
                                <span className="ms-2 mb-0">Cancel</span>
                            </div>
                        </Button>
                    )}
            </div>
        );
    };

    return (
        <div>
            <div className='row'>
                <div className='row align-items-center mb-3'>
                    <TopBar />
                </div>
                <div className="col-sm-4 d-flex fs-20 fw-800 secondary-color " style={{ minWidth: "20rem" }}>Procurement ID : <span className="mx-1 fs-20 fw-400 secondary-color">{orderHistoryItems?.procurement_display_id}</span></div>
                <div className="col-sm-4 mt-sm-0 mt-3 d-flex fs-20 fw-800 secondary-color">Store Name :<p className="mx-1 fs-20 fw-400 secondary-color">{orderHistoryItems?.store?.store_name}</p></div>
                <div className="col-sm-4 d-flex fs-20 fw-800 secondary-color"> Date :<p className="mx-1 fs-20 fw-400 secondary-color">{orderHistoryItems?.createdAt && moment(orderHistoryItems?.createdAt).format("DD/MM/YYYY")}</p></div>
            </div>
            <hr className="my-3" style={{ border: '1px dashed #222' }} />
           {isShowModifiedTable({ data: orderHistoryItems?.procurement_items, isContactLens: true }) &&  <div>
                <Table
                    AdditionalClassName="add-inventory-table table-height-50"
                    columns={columns}
                    data={modifiedTableData({ data: orderHistoryItems?.procurement_items, isContactLens: true })?.map((item: any) => {
                        return ({
                            ...item,
                            status:<TableItemStatus itemStatus={item.status} />,
                            action:tableActionSection({ item: item })
                        })
                    })}
                />
            </div>}
          {isShowModifiedTable({ data: orderHistoryItems?.procurement_items, isContactLens: false }) && <div>
                <Table
                    AdditionalClassName="add-inventory-table table-height-50"
                    columns={columns}
                    data={modifiedTableData({ data: orderHistoryItems?.procurement_items, isContactLens: false })?.map((item: any) => {
                        return ({
                            ...item,
                            status:<TableItemStatus itemStatus={item.status} />,
                            action:tableActionSection({ item: item })
                        })
                    })}
                />
            </div>}

            {deletePopup && <ConfirmationModal
                show={deletePopup}
                handleConfirmItem={() => { cancelButton(procurementItemId); setDeletePopup(!deletePopup) }}
                onHide={() => setDeletePopup(!deletePopup)}
                message={'cancel this procurement'} />}
        </div>
    )
}

export default Index