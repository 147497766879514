import React from "react";
import constant from "../../../constants/constant";
import atoms from "../../atoms";

const { StatusComponent } = atoms;

const Index = ({ itemStatus }: {itemStatus: number }) => {

  const indexItemStatus: string =
    itemStatus === constant.PROCUREMENT_ITEM_STATUS.PENDING
      ? "PENDING"
      : itemStatus === constant.PROCUREMENT_ITEM_STATUS.TO_BE_DELIVERED 
      ? "TO_BE_DELIVERED"
      : itemStatus === constant.PROCUREMENT_ITEM_STATUS.DELIVERED 
      ? "DELIVERED"
      : itemStatus === constant.PROCUREMENT_ITEM_STATUS.CANCEL 
      ? "CANCEL"
      : "";
  return <StatusComponent status={indexItemStatus} />;
};
export default Index;
