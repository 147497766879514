import React, { useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import service from '../../../../api/services'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import constant from '../../../../constants/constant'
const { AddBrand } = organisms
const Index = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [brandData, setBrandData] = useState({
        brand_logo: '',
        brand_name: "",
        brand_code: "",
        owner_name: "",
    })

    const addBrand = async () => {
        const payload = {
            brand_type: 2,
            brand_category: constant.BRAND_CATEGORY[1].value,
            brand_logo: brandData?.brand_logo,
            brand_name: brandData?.brand_name,
            brand_code: brandData?.brand_code,
            owner_name: "",
        }

        try {
            const response = await service.brandService.AddBrand(payload)
            if (response?.status === 201 || response?.status === 200) {
                // addToast('Brand Create Successfully', {
                //     appearance: 'success',
                //     autoDismiss: true,
                //     autoDismissTimeout: 3000,
                // });
                addToast(response.data.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate(-1)
            }
            // else{
            // }


        } catch (error: any) {
            console.log(error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleBrandSearchItem = async (searchValue: string) => {
        const payload = {
            brand_name: searchValue
        }
        try {
            let response = await service.brandService.UnRecognizedBrandSearch(payload)
            if (response.status === 200) {
                const updatedFiltered = [ { brand_name: searchValue, brand_code: '', brand_logo:"" }, ...response?.data?.data];
                setFilteredData(response?.data?.data?.length > 0 ? updatedFiltered : [{ brand_name: searchValue, brand_code: '' }]);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleSearch = (e: any) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);
        handleBrandSearchItem(searchValue)
    };


    const handleSelect = (value: any) => {
        setFilteredData([])
        setSearchTerm(value.brand_name)
        setBrandData((prevState: any) => ({
            ...prevState,
            brand_code: value.brand_code,
            brand_name: value.brand_name,
            brand_logo: value.brand_logo
        }));
        setSelectedValues(value)
    };

    return (
        <Wrapper>
            <AddBrand
                brandData={brandData}
                setBrandData={setBrandData}
                setCreateClick={() => addBrand()}
                handleSearch={handleSearch}
                searchTerm={searchTerm}
                filteredData={filteredData}
                handleSelect={handleSelect}
                selectedValues={selectedValues}
            />
        </Wrapper>
    )
}

export default Index