import React, { useRef, useState } from 'react'
import TopBar from '../../../molecules/TopBar'
import { useParams } from 'react-router';
import atoms from '../../../atoms';
import "./../index.scss";
import constant from '../../../../constants/constant';
const { Input, Image, Button } = atoms
const Index = ({
    brandData,
    setBrandData,
    setCreateClick,
    handleSearch,
    searchTerm,
    filteredData,
    handleSelect,
    selectedValues,

}: any) => {
    let params = useParams();
    let brandID = params.id || "";
    const ref: any = useRef(null);

    const [selectImage, setSelectImage] = useState(false);
    const [brandError, setBrandError] = useState({
        brand_logo: "",
        brand_name: "",
        brand_code: "",
    });
    const [errors, setErrors] = useState<any>({
        brand_margin: "",
        brand_discount: "",
        brand_landing_cost: "",
        specx_landing_cost: "",
    });

    const handleClick = (event: any) => {
        ref.current.click();
    };

    const handleImageChange = (event: any) => {
        const errorComponent: any = brandError;
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result;
            setBrandData({ ...brandData, brand_logo: base64String });
            setSelectImage(true);
            errorComponent.brand_logo = "";
            setErrors(errorComponent);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleChange = (name: string, value: any) => {
        const errorComponent: any = brandError;
        errorComponent[name] = "";
        setBrandData((prevState: any) => ({ ...prevState, [name]: value }));
        setBrandError(errorComponent);
    };

    const handleSubmit = () => {
        let brandDataError = false;
        const newErrors: any = {};
        let tempBrandError: any = {};
        if (
            brandData.brand_name === "" ||
            brandData.brand_code === ""
        ) {
            tempBrandError = {
                brand_name: brandData.brand_name.length > 0 ? "" : "Field is Required",
                brand_code: brandData.brand_code.length > 0 ? "" : "Field is Required",
            };
            setBrandError(tempBrandError);
            brandDataError = true;
        }
        if (brandDataError) {
            setErrors(newErrors);
            return;
        } else {
            setCreateClick(true);
        }
    }
    return (
        <>
            <div className="row">
                <div className="col-6 d-flex my-auto">
                    <TopBar heading={`${brandID ? "Update" : "Create"} Brand`} />
                </div>
            </div>
            <div className="d-flex">
                <div className="brand-profile mt-4">
                    <Image
                        className="div-fit-container"
                        src={selectImage ? brandData.brand_logo : brandData.brand_logo}
                        alt={"Add Brand Logo"}
                    />
                </div>
                <div
                    onClick={(e) => {
                        !selectedValues.brand_code && handleClick(e);
                    }}
                    className="brand-add-Button text-center add-image"
                >
                    <span style={{ fontSize: "20px" }}>+</span>
                </div>
                <input
                    type="file"
                    accept={"image/png, image/gif, image/jpeg, image/webp"}
                    ref={ref}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                />
                {brandError?.brand_logo?.length > 0 && (
                    <span className="error-text add-image-text-error">
                        {brandError?.brand_logo}
                    </span>
                )}
            </div>
            <div className="row mt-4">
                <div className="col-4">
                    <Input
                        type={"text"}
                        name="brand_name"
                        onChange={handleSearch}
                        value={searchTerm}
                        placeholder={"Enter Brand Name"}
                        label={"Brand Name"}
                        errors={brandError?.brand_name}
                    />
                    {searchTerm?.length > 0 && <ul className="list-group dropdown-list">
                        {filteredData.map((item: any, index: number) => (
                            <li
                                key={index}
                                className="list-group-item"
                                onClick={() => { handleSelect(item) }}
                            >
                                {item.brand_name}
                            </li>
                        ))}
                    </ul>}
                </div>
                <div className="col-4">
                    <Input
                        type={"text"}
                        onChange={(e: any) => handleChange("brand_code", e.target.value)}
                        value={brandData.brand_code}
                        placeholder={"Enter Brand Code"}
                        label={"Brand Code"}
                        errors={brandError?.brand_code}
                        disabled={selectedValues.brand_code}
                    />
                </div>
            </div>
            <div className="mt-5 d-flex justify-content-center ">
                <Button
                    className={""}
                    onClick={() => handleSubmit()}>
                    {`${brandID ? "Update" : "Create"} Brand`}
                </Button>
            </div>
        </>
    )
}

export default Index