import React from "react";
import atoms from "../../../atoms";
import PlaceHolderImage from '../../../../assets/Images/placeholderimg.png'
import { numberWithCommas,findConstantLabel } from "../../../../constants/utils";
import constant from "../../../../constants/constant";
import {MyComponentProps,cancelledListData } from "./interface"
import moment from "moment";
import molecules from "../../../molecules";

const { Pagination, Search, Table } = atoms
const {ContactLensContainer} = molecules

const Index = ({ transferRequestCancelledList, setSearch, currentPage, setCurrentPage, setLimitPerPage, limit,isContactLensProduct,setIsContactLensProduct }:MyComponentProps) => {

    const columns = [
        { name: 'Product ID', selector: (row: cancelledListData) => row?.procurement_item?.product?.product_id_display,minWidth:'10vw' },
        { name: 'Brand', selector: (row: cancelledListData) => row?.procurement_item?.product?.brand?.brand_name || '-', },
        {
            name: 'Cover Photo',
            cell: (row: cancelledListData) => {
                const style = {
                    height: "75%",
                    width: "100%",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${row?.procurement_item?.product?.images ? row?.procurement_item?.product?.images[0]:PlaceHolderImage})`
                }
                return <div style={style}></div>;
            },
        },
        { name: 'Model No', selector: (row: cancelledListData) => row?.procurement_item?.product?.modal_number, },
        { name: 'Brand Color Code', selector: (row: cancelledListData) => row?.procurement_item?.product?.brand_color_code || '-', },
        { name: 'With Case', selector: (row: cancelledListData) => row?.procurement_item?.product?.product_with_case ? "Yes" : "No", },
        { name: 'Date', selector: (row: cancelledListData) => moment(row?.createdAt).format('DD/MM/YYYY ,  H:mm:ss') || '-', },
        { name: 'Product Type', selector: (row: cancelledListData) => row?.procurement_item?.product?.product_type?.name, },
        { name: 'Rim Type', selector: (row: cancelledListData) =>  findConstantLabel(constant.PRODUCT_RIM_TYPE, row?.procurement_item?.product?.rim_type) || '-', },
        { name: 'Glass Size/Power', selector: (row: cancelledListData) => row?.procurement_item?.product?.glass_size || '-', },
        { name: 'SRP', selector: (row: cancelledListData) =>`₹${numberWithCommas(parseFloat(Number(row?.procurement_item?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: cancelledListData) =>`₹${numberWithCommas(parseFloat(Number(row?.procurement_item?.landing_cost)?.toFixed(2)))}` || '-', },
    ]

    const columnsForContactLens = [
        { name: 'Product ID', selector: (row: cancelledListData) => row?.procurement_item?.product?.product_id_display,minWidth:'10vw' },
        { name: 'Brand', selector: (row: cancelledListData) => row?.procurement_item?.product?.brand?.brand_name || '-', },
        {
            name: 'Cover Photo',
            cell: (row: cancelledListData) => {
                const style = {
                    height: "75%",
                    width: "100%",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${row?.procurement_item?.product?.images ? row?.procurement_item?.product?.images[0]:PlaceHolderImage})`
                }
                return <div style={style}></div>;
            },
        },
        { name: 'Date', selector: (row: cancelledListData) => moment(row?.createdAt).format('DD/MM/YYYY ,  H:mm:ss') || '-', },
        { name: 'Product Type', selector: (row: cancelledListData) => row?.procurement_item?.product?.product_type?.name, },
        { name: 'SRP', selector: (row: cancelledListData) =>`₹${numberWithCommas(parseFloat(Number(row?.procurement_item?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: cancelledListData) =>`₹${numberWithCommas(parseFloat(Number(row?.procurement_item?.landing_cost)?.toFixed(2)))}` || '-', },
    ]
 

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6"><Search searchCallBack={(e: string) => { setSearch(e) }} className="w-100" /></div>
                <div className="col-12 col-md-6 d-flex justify-content-end">
                <ContactLensContainer
                    value={isContactLensProduct}
                    onStatusChange={(value: boolean) => setIsContactLensProduct(value)}
                />
                </div>
            </div>
            <div>
                <Table
                    columns={isContactLensProduct ? columnsForContactLens : columns}
                    AdditionalClassName="table-height-50"
                    data={transferRequestCancelledList?.data?.map((item: any) => {
                        return ({
                            ...item
                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={transferRequestCancelledList?.count}
                    currentPage={currentPage}
                    totalPages={transferRequestCancelledList?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </>
    )
}

export default Index