import React from "react";
import { DataProps, IndexProps } from "./interface";
import atoms from "../../atoms";
import { FiEdit } from "react-icons/fi";

const { Table, Button } = atoms;

const Index = ({ printPrescription, setShowModal, defaultDays, setShowDaysModal }: IndexProps) => {
  const columns = [
    {
      name: "S.No.",
      selector: (row: { index_number: JSX.Element }) => row.index_number,
      maxWidth: "20px",
    },
    { name: "Name", selector: (row: DataProps) => row?.name, sortable: true },
    {
      name: "Description",
      selector: (row: DataProps) => row?.description,
      minWidth: "30%",
    },
    { name: "Type", selector: (row: { verification_status: JSX.Element }) => row?.verification_status },
    { name: "Action", selector: (row: { action: JSX.Element }) => row.action },
  ];

  const settingData: DataProps[] = [
    {
      name: "Print Prescription",
      description:
        "If the status is display, the lens prescription is display when creating the invoice. If the status is hide, the lens prescription is hide when creating the invoice",
      status: printPrescription,
      type: false
    },
    {
      name: "Order ready message",
      description:
        "-",
      status: defaultDays,
      type: true
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        AdditionalClassName="table-height-50"
        data={settingData?.map((item: any, index: number) => {
          return {
            ...item,
            index_number: index + 1,
            verification_status: (item.status && item?.type === false) ? (
              <div className="background-light-green border-product-radius">
                <p className="color-green m-1 p-1">{"Display"}</p>
              </div>
            ) : item?.type ? <div className="background-light-yellow border-product-radius">

              <p className="color-yellow m-1 p-1">{defaultDays} {defaultDays === 1 ? 'Day' : 'Days'}</p>
            </div> : (
              <div className="background-light-yellow border-product-radius">
                <p className="color-yellow m-1 p-1">{"Hide"}</p>
              </div>
            ),
            action: (
              <>
                <div style={{ overflow: "unset", textOverflow: "unset" }}>
                  <Button
                    className="table-edit-buttons fs-14 fw-500"
                    onClick={() => item.type ? setShowDaysModal(true) : setShowModal(true)}
                  >
                    <div className="d-flex align-items-center">
                      <FiEdit className="fs-20" />
                      <span className="ms-2 mb-0">{item.type ? 'Change Days' : 'Show/Hide'}</span>
                    </div>
                  </Button>
                </div>

              </>
            ),
          };
        })}
      />
    </div >
  );
};

export default Index;
