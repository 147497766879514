import React, { useContext, useState, useEffect } from 'react'
// import Logo from '../../../assets/Images/logo1.png'
import atoms from '../../../components/atoms'
import service from '../../../api/services'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications';
import Images from '../../../assets/Images';
import { StorePopup, SelectedCheckboxes } from '../../../Context';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { BASE_URL } from '../../../constants/defaultValues';

const { Input, Button } = atoms
const Index = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isUsernameValid, setUsernameValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [changeSubAdminScope, setChangeSubAdminScope] = useState<boolean>(false)
  const { setSelectedCheckboxes }: any = useContext(SelectedCheckboxes)
  const [isShow, setIsShow] = useContext<any>(StorePopup);


  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value);
    setUsernameValid(true);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setPasswordValid(true);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Perform validation
    let isValid = true;

    if (username.trim() === '') {
      setUsernameValid(false);
      isValid = false;
    }

    if (password.trim() === '') {
      setPasswordValid(false);
      isValid = false;
    }
    if (isValid) {
      let payload = {
        "grant_type": "password",
        "username": username,
        "password": password,
        "scope":changeSubAdminScope ? 7 :  3
      }
      const headers = {
        'Authorization': 'Basic YXBwbGljYXRpb246c2VjcmV0',
        "Content-Type": "application/x-www-form-urlencoded"
      };
      try {
        let response = await service.auth.login(payload, headers)
        if (response?.status === 200) {
          setIsShow(true)
          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          localStorage.setItem("access_token", response?.data?.data?.accessToken)
          localStorage.setItem("username", response?.data?.data?.username)
          localStorage.setItem("store_name", response?.data?.data?.store_name)
          localStorage.setItem("store_code", response?.data?.data?.store_code)
          localStorage.setItem("scope", response?.data?.data?.scope)
          // Set store_location in localStorage recieved from response of login api: 
          {
            response?.data?.data?.store_location !== null ? (
              localStorage.setItem("store_location", response?.data?.data?.store_location))
            : localStorage.setItem("store_location", "")
          }
          localStorage.setItem("refresh_token", response.data.data.refreshToken)
          navigate("/dashboard")
        }else{
          addToast("Invalid Credentials", {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        }
      } catch (error: any) {
        console.log(error)
        addToast(error?.response?.data?.message, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }

    }
  };

  const handleSubAdminLogin=()=>{
    setChangeSubAdminScope(!changeSubAdminScope)
    setUsername('');
    setPassword('');
  }

  useEffect(() => {
    setSelectedCheckboxes({})
    const AUTH_TOKEN = localStorage.getItem("access_token")

    if (AUTH_TOKEN) {
      const previousUrl = document.referrer;
      if (previousUrl.startsWith(BASE_URL || "")) {
        navigate(-1);
      } else {
        navigate('/');
      }
    }
  }, [navigate]);

  return (
    <div className='p-md-5 d-flex justify-content-center align-items-center login-layout-container' >
      <div className='card mx-auto border-0 login-container'>
        <div className='card-body dashboard-card-height p-4' >
          <div className='row'>
            <div className='col-md-6 col-12 align-items-center d-flex' style={{ borderRight: '1px solid #00000050' }}>
              <div className="p-3">
                <img src={Images.LoginImage} alt="login form" className='w-100' />
              </div>
            </div>
            <div className='col-md-6 col-12 justify-content-center'>
              <div className="col-sm-10 col-lg-10 align-items-center">
                <div className="card-body text-black">
                  <form onSubmit={handleSubmit}>
                    {/* <div className="mb-4">
                      <img src={'Logo'} className="fa-2x me-3 w-75" alt='' />
                    </div> */}
                    <div className="d-flex align-items-center mb-3 pb-1">
                      <span className="h1 fw-600 mb-0">Store {`${changeSubAdminScope ? "Subadmin" : "Admin"}`} </span>
                    </div>

                    <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: "1px", textDecoration: 'underline' }}>Sign into your account</h5>

                    <div className="form-outline mb-4">
                      <Input type={"text"} className={!isUsernameValid ? 'error' : ''} value={username}
                        onChange={handleUsernameChange} label={"Username"} defaultValue={username} placeholder={'Enter Username'} />
                      {!isUsernameValid && <p className="error-text">Username is required</p>}
                    </div>

                    {/* <div className="form-outline mb-4">
                      <Input type={!showPassword ? "password" : "text"} onChange={handlePasswordChange}
                        className={!isPasswordValid ? 'error' : ''} label={"Password"} defaultValue={password} placeholder={'Enter Password'} />
                      {!isPasswordValid && <p className="error-text">Password is required</p>}
                      {<span
                        className='position-absolute cursor-pointer'
                        style={passwordShowIconStyle}
                        onClick={() => setShowPassword(!showPassword)}>{showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                      </span>}
                    </div> */}
                    <div className="form-outline mb-4 position-relative">
                      <Input type={!showPassword ? "password" : "text"} onChange={handlePasswordChange}
                      value={password}
                        // className={!isPasswordValid ? 'error' : ''} 
                        label={"Password"} defaultValue={password} placeholder={'Enter Password'} />
                      <div className='view-password-icon'>
                        <span className='cursor-pointer'
                          onClick={() => setShowPassword(!showPassword)}>{showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </span>
                      </div>
                      {!isPasswordValid && <p className="error-text">Password is required</p>}
                    </div>

                    <div className="pt-1 mb-4 text-center">
                      <Button className={"btn btn-dark w-100 btn-block w-50"} type={"submit"}>Login</Button>
                    </div>
                    <div className='mt-3'>
                        <p className="small text-muted cursor-pointer" onClick={()=>handleSubAdminLogin()} >Login as {`${changeSubAdminScope ? "Store Admin" :"Store Subadmin"}`}</p>
                    </div>
                    {/* <div>
                        <a className="small text-muted" href="#!">Forgot password?</a>
                      </div> */}
                    {/* <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}></p> */}
                    {/* <div className='d-flex justify-content-between'>
                      <a href="#!" className="small text-muted">Terms of use</a>
                      <a href="#!" className="small text-muted">Privacy policy</a>
                    </div> */}
                  </form>

                </div>
              </div>
              <div className='d-none d-lg-flex col-lg-2'></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Index