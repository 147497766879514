import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { store } = Dictionary

const storeService = {
    storeList: async () => {
        return axios.get(BASE_URL + store.storeList())
    },
    switchStore: async (storeId: string) => {
        return axios.get(BASE_URL + store.switchStore(storeId))
    },
    searchUser: async (userId: string, paramObj?:{}) => {
        return axios.get(BASE_URL + store.searchUser(userId), {params:paramObj})
    },
}
export default storeService
