import React from "react";
import Select from "react-select";
import './Select.scss'

export const ReactSelect = ({ className, placeholder,
    options, value,
    onChange, isMulti,
    menuIsOpen, isDisabled, defaultValue,label, htmlFor ,errors,isOptionDisabled
}: any) => {
    const handleChange = (option: any) => {
        onChange(option);
    };
    const customStyles = {
        valueContainer: (provided:any, state:any) => ({
          ...provided,
          height: "45px",
          overflowY: 'scroll',
        }),
        menu:(provided:any, state:any) => ({
            ...provided,
            zIndex:5,
          }),
      };

    return (
        <>
            <label className='inputs-label mb-2 fs-16 fw-600  secondary-color' htmlFor={htmlFor}>{label}</label>
            <Select
                isDisabled={isDisabled}
                menuIsOpen={menuIsOpen}
                className={`multi_select ${className}`}
                // name={field.name}
                defaultValue={defaultValue}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                isSearchable={true}
                styles={customStyles}
                isOptionDisabled={isOptionDisabled}
            />
             {errors && <span className="error-text">{errors}</span>}
        </>
    );
};

export default ReactSelect;
