import React from 'react';
import PropTypes from 'prop-types';

const statusStyles:Record<string, { background: string; color: string }> = {
    PENDING: {
        background: 'light-yellow',
        color: 'yellow'
    },
    TO_BE_DELIVERED: {
        background: 'light-blue',
        color: 'blue'
    },
    DELIVERED: {
        background: 'light-green',
        color: 'green'
    },
    CANCEL: {
        background: 'light-red',
        color: 'red'
    }
};

const isValidStatus = (status:string) => {
    return status in statusStyles;
};

const StatusComponent = ({ status }:{status:string}) => {
    if (!isValidStatus(status)) {
        return null; 
    }
    const style = statusStyles[status];
    return (
        <div className={`background-${style.background} border-product-radius`}>
            <p className={`color-${style.color} m-1 p-1`}>{status}</p>
        </div>
    );
};

StatusComponent.propTypes = {
    status: PropTypes.string.isRequired,
};

export default StatusComponent;
