import React, { useContext, useEffect, useState } from "react";
import organisms from "../../../components/organisms";
import { Default_Page_Limit, Default_Page_Number } from "../../../constants/defaultValues";
import { HeaderHeading } from "../../../Context";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import service from "../../../api/services";

const { SubAdmin } = organisms;

const Index = () => {
    const [staffData, setStaffData] = useState([]);
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState("");
    const { setHeader }: any = useContext(HeaderHeading);

    const getSubAdminData = async () => {
        try {
            let response = await service.staff.subAdminView({
                page: currentPage,
                search: search,
                limit: limitPerPage,
            });
            if (response.status === 200) {
                setStaffData(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const response = await service.staff.delete(id);
            if (response.status == 200) {
                getSubAdminData();
            }
        } catch (error) { }
    };

    useEffect(() => {
        setHeader("Subadmin List");
        getSubAdminData();
    }, [currentPage, search, limitPerPage]);

    return (
        <Wrapper>
            <SubAdmin
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                limitPerPage={limitPerPage}
                currentPage={currentPage}
                setSearch={setSearch}
                subAdminData={staffData}
                _handleDelete={(value: string) => handleDelete(value)}
            />
        </Wrapper>
    );
};

export default Index;
