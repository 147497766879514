import React, { useEffect, useState, SetStateAction } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import organisms from '../../../../components/organisms';
import { getDropdown, getParams, getUniqueObj } from '../../../../constants/utils';
import moment from 'moment';
import { getDropdownValue } from '../../../../constants/utils'
import constant from '../../../../constants/constant';
import { AddInvoiceData, SearchProduct, InvoiceEditResponse, product_details } from './interface'
const { AddInventoryOrganism } = organisms
const AddInventory = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    let params = useParams();
    let invoiceEditID = params.id || ''
    const [typeValue, setTypeValue] = useState<any>(getParams('isRecognized'))
    const [search, setSearch] = useState<string>('')
    const [productData, setProductData] = useState<any>([])
    const [allProductList, setAllProductList] = useState([])
    const [supplierData, setSupplierData] = useState([])
    const [previewTab, setPreviewTab] = useState(false)
    const [buttonClick, setButtonClick] = useState(false)
    const [totalProduct, setTotalProduct] = useState({
        sum_qty: 0,
        sum_total_price: 0,
        total_product_case: 0,
        total_gst_amount: 0,
        total_srp_amount: 0
    })
    const [invoiceData, setInvoiceData] = useState<AddInvoiceData>({
        invoice_id: '',
        invoice_count: '',
        supplier_invoice_number: "",
        supplier_invoice_date: "",
        category: '',
        actual_invoice: '',
        total_product_case: '',
        total_gst: '',
        total_srp: '',
        total_amount: '',
        remark: "",
        product_details: [],
        local_supplier_name:""
    })

    const getProductData = async (data: string) => {
        try {
            let response = await service.inventory.addInventorySearch({ "search": data, is_recognised: typeValue })
            const updateProductKeys: SetStateAction<never[]> = response?.data?.data?.map((product: SearchProduct) => {

                let qty = 1;
                let tempGstAmount =
                    (
                        product.product_srp * getUniqueObj(product.brand.product_types, "product_type_id", product.product_type_id)?.specx_landing_cost
                    ) / 100
                let tempTotalCostAmount = (tempGstAmount) * qty
                let gst_amount = (tempTotalCostAmount * product.product_type.gst_percentage) / (100 + product.product_type.gst_percentage)
                let cost_amount: number | string | any = ((product?.product_srp || 0) * (getUniqueObj(product?.brand?.product_types, "product_type_id", product?.product_type_id)?.specx_landing_cost) * 0.01).toFixed(2)
                let total_price = cost_amount * qty;
                let srp_amount = product?.product_srp * qty
                return {
                    ...product,
                    cost_amount,
                    qty,
                    total_price,
                    gst_amount,
                    srp_amount
                };
            });

            setAllProductList(updateProductKeys)
        } catch (error) {
            console.error(error)
        }
    }
    const handleSearchSelect = (e: SearchProduct) => {
        let tempProductData: SearchProduct | any = [...productData]
        tempProductData.unshift(e);
        // tempProductData.push(e);
        let uniqueObjects: any = Array.from(new Set(tempProductData?.map((obj: any) => obj.id)))
            .map(id => tempProductData?.find((obj: any) => obj.id === id));
       
            let customProduct:any = uniqueObjects.map((item: any, index: number) => ({
                ...item,
                custom_index: index,
            }));
        setProductData(customProduct)
        if (tempProductData > uniqueObjects) {
            return
        }
        let tempTotal = {
            sum_qty: e.qty ? e?.qty + totalProduct?.sum_qty : totalProduct?.sum_qty,
            sum_total_price: e?.total_price ? e?.total_price + totalProduct?.sum_total_price : totalProduct?.sum_total_price,
            total_product_case: e?.product_with_case ? e?.qty + totalProduct?.total_product_case : totalProduct?.total_product_case,
            total_gst_amount: e?.gst_amount ? e?.gst_amount + totalProduct?.total_gst_amount : totalProduct?.total_gst_amount,
            total_srp_amount: e?.srp_amount ? e?.srp_amount + totalProduct?.total_srp_amount : totalProduct?.total_srp_amount,
        }
        let tempProductDetail: any = []
        tempProductDetail.push({
            product_id: e.id,
            cost_amount: (e.cost_amount || 0),
            quantity: (e.qty || 1),
            gst_amount: e.gst_amount,
            srp_amount: e.srp_amount
        })
        setInvoiceData((prevState: AddInvoiceData) => ({ ...prevState, product_details: tempProductDetail }));
        setInvoiceData((prevState: AddInvoiceData) => ({ ...prevState, total_product_case: tempTotal.total_product_case }));
        setInvoiceData((prevState: AddInvoiceData | any) => ({ ...prevState, total_amount: tempTotal?.sum_total_price }));
        setInvoiceData((prevState: AddInvoiceData | any) => ({ ...prevState, total_gst: tempTotal?.total_gst_amount }));
        setInvoiceData((prevState: AddInvoiceData | any) => ({ ...prevState, total_srp: tempTotal?.total_srp_amount }));
        setTotalProduct(tempTotal)

    }
    const getSupplierData = async () => {
        try {
            let SupplierResponse = await service.inventory.supplier()
            setSupplierData(getDropdown(SupplierResponse?.data?.data, "supplier_name"))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (buttonClick) {
            addInvoice()
        }
    }, [buttonClick])

    useEffect(() => {
        getSupplierData()
    }, [])

    const getInvoiceDataById = async () => {
        try {
            let response = await service.inventory.getDetailById(invoiceEditID)
            handleEditData(response.data.data)
        } catch (error) {
            console.log("error", error)
        }
    }
    const handleEditData = (response: InvoiceEditResponse) => {
        let tempDetail: any = []
        let tempProductDetail: any = []
        let tempTotalQty = 0
        if (response?.product_details) {
            response.product_details.map(async (value: product_details, index: number) => {
                value.product["cost_amount"] = (value.cost_amount || 0);
                value.product["qty"] = (value.quantity || 1);
                value.product["total_price"] = value.total_price;
                value.product["gst_amount"] = value.gst_amount;
                tempTotalQty += value.quantity
                tempDetail?.push(value.product)
                tempProductDetail.push({
                    product_id: value.product.id,
                    cost_amount: value.cost_amount,
                    quantity: value.quantity,
                    gst_amount: value.gst_amount
                })
            });
        }
        let tempTotal: SetStateAction<any> = {
            sum_qty: tempTotalQty,
            sum_total_price: response?.total_amount,
            total_product_case: response?.total_product_case,
            total_gst_amount: response?.total_gst,
            total_srp_amount: response?.total_srp_amount
        }
        
        let tempInvoiceData: AddInvoiceData = {
            // supplier_id: supplierData && getDropdownValue(supplierData, response?.supplier.id),
            local_supplier_name: response?.local_supplier_name,
            supplier_invoice_number: response?.supplier_invoice_number,
            supplier_invoice_date: moment(response?.supplier_invoice_date).format('YYYY-MM-DD'),
            category: getDropdownValue(constant.STORE_INVOICE_CATEGORY, response.category),
            actual_invoice: response.actual_invoice,
            total_product_case: tempTotal.total_product_case,
            total_gst: tempTotal.total_gst_amount,
            total_amount: tempTotal.total_gst_amount,
            remark: response.remark,
            product_details: tempProductDetail,
            invoice_id: response?.invoice_id_display,
            invoice_count: response?.invoice_id_display.slice(-3),
        }
        let customProduct:any = tempDetail.map((item: any, index: number) => ({
            ...item,
            custom_index: index,
        }));
        setProductData(customProduct)
        setTotalProduct(tempTotal)
        setInvoiceData(tempInvoiceData)
    }

    const addInvoice = async () => {
        let payload: AddInvoiceData = {
            invoice_id: invoiceData.invoice_id,
            // supplier_id: invoiceData.supplier_id.value,
            local_supplier_name: invoiceData.local_supplier_name,
            supplier_invoice_number: invoiceData.supplier_invoice_number,
            supplier_invoice_date: invoiceData.supplier_invoice_date,
            category: invoiceData.category.value,
            actual_invoice: invoiceData.actual_invoice,
            total_product_case: invoiceData.total_product_case,
            total_gst: invoiceData.total_gst,
            total_amount: invoiceData.total_amount,
            remark: invoiceData.remark,
            product_details: invoiceData.product_details,
            total_srp_amount: invoiceData.total_srp_amount
        }
        try {
            let response: any
            if (invoiceEditID) {
                response = await service.inventory.edit(invoiceEditID, payload)
            } else {
                response = await service.inventory.add(payload)
            }
            if (response.status === 201) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate(-1)
            } else if (response?.status === 200) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                // navigate(-1)
                navigate(-1)
            }
        } catch (error: any) {
            addToast(error.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
        setButtonClick(false)
    }
    useEffect(() => {
        if (invoiceEditID && supplierData?.length > 0) {
            getInvoiceDataById()
        }
        if (!params.id  && supplierData?.length > 0) {
            getCount()
        }
    }, [supplierData])

    const getCount = async () => {
        try {
            let response: any = await service.inventory.getInvoiceDataCount()
            if (response.status === 200) {
                setInvoiceData({ ...invoiceData, invoice_count: response.data.count })
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <Wrapper>
            <AddInventoryOrganism
                search={search}
                setSearch={setSearch}
                supplierData={supplierData}
                productData={productData}
                allProductList={allProductList}
                handleSearchSelect={(e: any) => handleSearchSelect(e)}
                setProductData={setProductData}
                previewTab={previewTab}
                setPreviewTab={setPreviewTab}
                setAllProductList={setAllProductList}
                totalProduct={totalProduct}
                setTotalProduct={setTotalProduct}
                invoiceData={invoiceData}
                setInvoiceData={setInvoiceData}
                setButtonClick={(e: any) => setButtonClick(e)}
                getProductData={(data: string) => getProductData(data)}
            />
        </Wrapper>
    )

}
export default AddInventory