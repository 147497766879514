import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import { findConstantLabel, getParams } from '../../../../constants/utils'
import constant from '../../../../constants/constant'
import service from "../../../../api/services";
import atoms from '../../../../components/atoms'
import { useToasts } from 'react-toast-notifications';
import * as XLSX from "xlsx"
import { HeaderHeading } from '../../../../Context'

import { NULL } from 'sass'
import { BULKUPLOAD_SAMPLE } from '../../../../constants/defaultValues'
import { useSearchParams } from 'react-router-dom'


const { BulkUpload } = organisms
const { Input, Button } = atoms

const Index = () => {
  const [searchParams] = useSearchParams();
  const [typeValue, setTypeValue] = useState<any>(getParams('bulk-product-type'))
  const [data, setData] = useState<any>([])
  const [imageData, setImageData] = useState<string[]>()
  const [imageApiData, setImageApiData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)

  // make useState for store api data for validation
  const [productTypeValid, setProductTypeValid] = useState<any>([]);
  const [brandData, setBrandData] = useState([])
  const [productMaterial1, setProductMaterial1] = useState<any>([])
  const [swColorName, setSWColorName] = useState([])
  const [errorRow, setErrorRow] = useState<any>([])
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);

  const { addToast } = useToasts();
  useEffect(() => {
    setTypeValue(getParams('bulk-product-type'))
  }, [searchParams])


  useEffect(() => {
    setHeader("Product - Bulk Upload")
    setErrorRow([])
    fetchData()
  }, [typeValue])

  const isNumber = (n: any) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }
  // call api for validition of upload xlsx file column data
  const fetchData = async () => {
    // setIsLoading(false)
    setImageData([])
    try {
      const productTypeResponse = await service.product.productTypeList({ search: '' });
      if (productTypeResponse.status === 200) {
        setProductTypeValid(productTypeResponse.data.data);
      }

      const brandResponse = await service.product.brandListingService({ search: '', brand_category: typeValue });
      if (brandResponse.status === 200) {
        setBrandData(brandResponse.data.data);
      }

      const productMaterialResponse = await service.product.productMaterialListingService({ search: '' });
      if (productMaterialResponse.status === 200) {
        setProductMaterial1(productMaterialResponse.data.data);
      }

      const swColorResponse = await service.product.productColorListingService({ search: '' });
      if (swColorResponse.status === 200) {
        setSWColorName(swColorResponse.data.data);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // setIsLoading(true)
  };

  const validateXlsxFileData = async (fileData: any) => {
    const errors: any[] = [];

    fileData.forEach((data: any) => {
      const { product_type_initials: type, brand_code: brand, brand_color_code, glass_size, isPolarised, modal_number, product_material, sw_color_name, weight, warranty, rim_type, gender } = data;

      if (!type || !brand || !brand_color_code || !glass_size || !isPolarised || !modal_number) {
        errors.push({ id: data.image_folder_name });
      }

      if (type || brand || brand_color_code || glass_size || isPolarised || modal_number || product_material) {
        if (!validProductType(type)) {
          errors.push({ id: data.image_folder_name, message: type, error: `${type} Not Found`, column: "Product Type Initials" });

        }
        if (!validBrandCode(brand)) {
          errors.push({ id: data.image_folder_name, message: brand, error:brand ? `${brand} Not Found` : "Empty", column: "Brand Code" });
        }

        if (product_material) {
          let productMaterialError = validateProductMaterial(product_material)
          if (productMaterialError?.length > 0) {
            errors.push({ id: data.image_folder_name, message: product_material, error: `${productMaterialError} Not Found`, column: "Product Material" });
          }
        }

        if (sw_color_name) {
          const swColorNameError = validateSwColorName(sw_color_name)
          if (swColorNameError?.length > 0) {
            errors.push({ id: data.image_folder_name, message: sw_color_name, error: `${swColorNameError} Not Found`, column: "sw_color_name" });
          }
        }

        if (isPolarised > 3 || !isNumber(isPolarised)) {
          errors.push({ id: data.image_folder_name, message: "Polarised should be 1, 2 or 3", error: `${isPolarised} Not Found`, column: "isPolarised" });
        }

        if (weight && !isNumber(weight)) {
          errors.push({ id: data.image_folder_name, error: "should be integer", column: "Weight" });

        }
        if (warranty && !isNumber(warranty)) {
          errors.push({ id: data.image_folder_name, error: "should be integer", column: "Warranty" });
        }
        if (rim_type && !isNumber(rim_type)) {
          errors.push({ id: data.image_folder_name, error: "should be integer", column: "Rim_type" });
        }
        // if(gender && !isNumber(gender)){
        // errors.push({ id: data.image_folder_name, error:"should be integer", column: "Gender" });
        // }
      }

    });

    await setErrorRow(errors);
    // !fileData ? setIsLoading(true) : setIsLoading(false)
    setData(fileData);
  };

  const validateSwColorName = (swColor: any) => {
    const arr = (swColor && !isNumber(swColor)) && swColor?.split(',')
    let err: any = []
    for (let i = 0; i < arr?.length; i++) {
      const found = swColorName?.find((element: any) => element?.color_name == arr[i]);
      if (!found) {
        err.push(arr[i])
      }
    }
    return err
  }
  const validateProductMaterial = (productMaterialName: any) => {

    const arr = (productMaterialName && !isNumber(productMaterialName)) && productMaterialName?.split(',')
    let err: any = []
    for (let i = 0; i < arr.length; i++) {

      const found = productMaterial1?.find((element: any) => element?.name?.trim() == arr[i]?.trim());
      if (!found) {
        err.push(arr[i])
      }
    }
    return err
  }

  const validProductType = (type: string): boolean => {
    return productTypeValid?.some((element: any) => element?.product_type_initials === type);
  };

  const validBrandCode = (brand: string): boolean => {
    return brandData?.some((element: any) => element?.brand_code === brand);
  };

  const handleFileUpload = (e: any) => {
    setIsLoading(false)
    const reader = new FileReader();
    reader.onload = (event: any) => {
      try {
        const data = event.target.result;
        if (typeof data === 'string') {
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedData: any = XLSX.utils.sheet_to_json(sheet);
          // setApiErrors(parsedData);
          validateXlsxFileData(parsedData)
        } else {
          throw new Error('Invalid file data');
        }
      } catch (error) {
        console.error('Error parsing Excel file:', error);
      }
    };

    reader.onerror = (event: ProgressEvent<FileReader>) => {
      console.error('Error reading the file:', event?.target?.error);
    };

    if (e.target.files && e.target.files[0]) {
      reader.readAsBinaryString(e?.target?.files[0]);
    } else {
      console.error('No file selected');
    }
  };

  // extract image folder Name for image file upload
  const extractNumber = (inputString: string) => {
    const regex = /\/(\d+)\//; // Regular expression to match numbers between slashes
    const match = inputString.match(regex); // Match the regular expression against the input string
    return match ? match[1] : null; // Return the captured number, or null if no match is found
  }

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = event.target.files; // this is upload file data 
    setImageApiData(files)
    const newImageDataUrls: any[] = [];
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        const number = extractNumber(files[i]?.webkitRelativePath)
        // const imageUrl = URL.createObjectURL(files[i]);
        // newImageDataUrls.push({ id: number, imageUrl: imageUrl }); // this is image url in upload file data
        // setImageData(newImageDataUrls)

        reader.onload = (e) => {
          // Push the data URL to the newImageDataUrls array
          if (e.target && typeof e.target.result === 'string') {
            newImageDataUrls.push({ id: number, imageUrl: e.target.result }); // this is image url in upload file data
          }

          // If all files are processed, update the state with the newImageDataUrls array
          if (i === files.length - 1) {
            setImageData(newImageDataUrls)
          }
        };
        reader.readAsDataURL(files[i]); // Read the file as a data URL
      }
    }
  };

  // const imageForApi = (imageFolderName: number, ApiImageData: any[]): any[] => {
  //   return ApiImageData
  //     .filter((file) => {
  //       const number = extractNumber(file?.webkitRelativePath);
  //       return number && parseInt(number) === imageFolderName;
  //     });
  // }


  const productLabel = (type: any, data: any, label: any, searchLabel: any) => {
    // return productTypeValid.some((element: any) => element.product_type_initials === data?.product_type_initials);
    for (let i = 0; i < data?.length; i++) {
      if (type == data[i][searchLabel]) {
        return data[i][label].toUpperCase()
      }
    }
  }
  const getDescription = (items: any) => {
    if (items) {
      let tempProductType = items?.product_type_initials ? productLabel(items?.product_type_initials, productTypeValid, 'name', 'product_type_initials') : '';
      let tempBrandID = items?.brand_code ? productLabel(items?.brand_code, brandData, 'brand_name', 'brand_code') : '';
      let tempProductRimType = items?.rim_type ? findConstantLabel(constant.PRODUCT_RIM_TYPE, parseInt(items?.rim_type)) : '';
      let tempColorCode = items?.brand_color_name || null;
      // const colorCodeLabels = tempColorCode;
      let tempProductShape = items?.product_shape || "";
      // const productShapeLabels =items[0]?.product_shape && tempProductShape.map((shape: any) => shape.label)?.join(' ');
      let tempPolarized = items?.isPolarised === 1 ? 'POLARIZED' : '';
      let tempDescription = [tempBrandID, tempColorCode ? tempColorCode + ' ' + 'COLOR' : '', tempProductShape, tempProductRimType, tempPolarized, tempProductType,];
      tempDescription = tempDescription?.filter(value => value && value.trim() !== '')
      const description = tempDescription?.join(' ');
      return description
    }
  }

  const imageForApi = (imageFolderName: number, data: any) => {
    let files = data
    let tempImage: any = []
    for (let i = 0; i < files?.length; i++) {
      const number = extractNumber(files[i]?.webkitRelativePath)
      if (number) {
        let id = parseInt(number)
        if (id == imageFolderName) {
          tempImage.push(files[i])
        }
      }
    }
    return tempImage
  }

  const handleBulkSubmit = async () => {
    const errors: any[] = [];
    // setApiErrors([])
    for (let i = 0; i < data?.length; i++) {
      const description = await getDescription(data[i])
      const imageFolderName = data[i]?.image_folder_name
      const image = imageForApi(imageFolderName, imageApiData)
      await handelBulkUploadApi(data[i], image, description, errors)
    }
    addToast('File Upload successfully', {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    setErrorRow(errors);
  }
  // Function to handle the api of bulk upload
  const handelBulkUploadApi = async (items: any, productImage: any, description: any, errors: any) => {
    const formData = new FormData();
    { items?.modal_number && formData.append('modal_number', items?.modal_number); }
    { items?.brand_color_code && formData.append('brand_color_code', items?.brand_color_code) };
    { items?.brand_color_name && formData.append('brand_color_name', items?.brand_color_name) };

    { items?.glass_size && formData.append('glass_size', items?.glass_size); }
    { items?.temple_size && formData.append('temple_size', items?.temple_size); }
    { items?.bridge_size && formData.append('bridge_size', items?.bridge_size ? items?.bridge_size : ""); }

    { items?.features && items?.features && formData.append('features', items?.features); }
    { items?.rim_type && formData.append('rim_type', items?.rim_type); }
    { items?.gender && formData.append('gender', items?.gender); }

    { items?.product_srp && formData.append('product_srp', items?.product_srp); }
    { items?.warranty && formData.append('warranty', items?.warranty); }
    { items?.weight && formData.append('weight', items?.weight); }

    { items?.product_with_case && formData.append('product_with_case', items?.product_with_case); }
    // { items?.description && formData.append('description', items?.description ? items?.description : ""); }
    { items?.product_type_initials && formData.append('product_type_initials', items?.product_type_initials); }

    { items?.brand_code && formData.append('brand_code', items?.brand_code); }
    { items?.product_shape && formData.append('product_shape', items?.product_shape); }
    { productImage.length > 0 && productImage?.forEach((image: any) => formData.append('product_images', image)); }

    { items?.sw_color_name && formData.append('sw_color_name', items?.sw_color_name); }
    { items?.product_material && formData.append('product_material', items?.product_material); }
    { items?.isPolarised && formData.append('isPolarised', items?.isPolarised); }
    { items?.sw_lens_color && formData.append('sw_lens_color', items?.sw_lens_color); }
    { description && formData.append('description', `${description}, ${items?.description ? items?.description : ""} `); }
    try {
      await service.product.bulkProductUpload(formData, { "Content-type": "multipart/form-data" })
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        // setApiErrors((prevErrors: any) => [...prevErrors, items]);
        // setApiErrors((prevErrors:any) => [...prevErrors, { items: items, message: error.response.data.message }]);
        errors.push({ id: items?.image_folder_name, error: error.response.data.message });

      } else {
        errors.push({ id: items?.image_folder_name, error: "An error occurred while processing your request" });
        // setApiErrors((prevErrors: any) => [...prevErrors, 'An error occurred while processing your request.']);
      }
    }
  }

  const handleRemove = (id: string) => {
    let arr = [...data]
    const index = data.findIndex((item: any, index: number) => {
      if (item?.image_folder_name == id) {
        return index
      }
    })

    if (index > -1) { // only splice array when item is found
      arr.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      arr.splice(0, 1);
    }
    setData(arr)
  }

  // const handleRemoveAll = () => {
  //   let arr = [...data]
  //   for (let i = 0; i < errorRow?.length; i++) {
  //     const index = data.findIndex((item: any, index: number) => {
  //       if (item?.image_folder_name == errorRow?.id) {
  //         return index
  //       }
  //     })

  //     if (index > -1) {
  //       arr.splice(index, 1);
  //     } else {
  //       arr.splice(0, 1);
  //     }
  //   }
  //   setData(arr)
  //   setErrorRow([])
  // }

  const handleDownloadSampleFormate = async () => {
    window.location.href = BULKUPLOAD_SAMPLE || ''
  };

  useEffect(() => {
    setIsLoading(true)
  }, [data])

  return (
    <Wrapper>
      <div className="row">
        <div className='col '>
          <div className="px-2 d-flex justify-content-between">
            <div className='d-flex  align-items-center'>
              <label className='inputs-label mb-2 fs-16 fw-600  secondary-color'>{"Upload XLSX File"}</label>
              <Input
                type="file"
                id="formFile"
                accept='.xlsx, .xls, .csv'
                multiple={true}
                onChange={handleFileUpload}
                placeholder={"formate :- .xlsx, .xls"}
                onClick={(e: any) => (e.target.value = null)}
              />
            </div>
            <div>
              <Button onClick={() => handleDownloadSampleFormate()}>Download Format </Button>
            </div>
          </div>
        </div>

        {isLoading ? <BulkUpload
          data={data}
          imageData={imageData}
          handleFileUpload={handleFileUpload}
          handleImageUpload={handleImageUpload}
          handleBulkSubmit={handleBulkSubmit}
          errorRow={errorRow}
          handleRemove={handleRemove}
        // handleRemoveAll={handleRemoveAll}
        /> :
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              alignItems: 'center'
            }}
            className="loading-One"
          >
            <div
              style={{
                position: 'fixed',
                top: '50%',
                left: "50%",
                zIndex: '9999'
              }}
              className="spinner-border text-primary loading-Two" role="status">

              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
      </div>
    </Wrapper>
  )
}

export default Index