import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import constant from "../../../../constants/constant"
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues"
import organisms from '../../../../components/organisms'
import { HeaderHeading } from '../../../../Context'
import { useSearchParams } from 'react-router-dom'
import { getParams } from '../../../../constants/utils'
import { constants } from 'buffer'

const { ApprovedInventory } = organisms

const Index = () => {
  const [searchParams] = useSearchParams();
  const [typeValue, setTypeValue] = useState<any>(getParams('inventory-approved-type') == constant.BRAND_CATEGORY[0]?.value)
  const [initialLoading, setInitialLoading] = useState(false)
  const [transferRequestMoveOutList, setTransferRequestMoveOutList] = useState<any>();
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(Default_Page_Number);
  const [search, setSearch] = useState<string | undefined>();
  const [showSidebarModal,setShowSidebarModal]= useState<boolean>(false)
  const { setHeader }: any = useContext(HeaderHeading);

  useEffect(() => {
    setTypeValue(getParams('inventory-approved-type') == constant.BRAND_CATEGORY[0]?.value)
    setTransferRequestMoveOutList({})
    setCurrentPage(Default_Page_Number)
    setLimitPerPage(Default_Page_Limit)
    setSearch('')
  }, [searchParams])

  const getInventory = async () => {
    try {
      const response = await service.inventory.view({
        "page": currentPage,
        "search": search,
        "limit": limitPerPage,
        "status": constant.VERIFICATION_STATUS.VERIFIED,
        is_recognised: typeValue
      })
      if (response.status === 200) {
        setTransferRequestMoveOutList(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setHeader("Inventory - Approved")
    if (initialLoading) {
      getInventory()
    }
    setInitialLoading(true)
  }, [currentPage, typeValue])

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1) {
      getInventory()
    }
  }, [limitPerPage, search])

  return (
    <Wrapper>
      <ApprovedInventory
        approveInventoryData={transferRequestMoveOutList}
        setSearch={setSearch}
        limit={limitPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLimitPerPage={setLimitPerPage}
        isRecognized={typeValue == constant.BRAND_CATEGORY[0]?.value}
        setShowSidebarModal={setShowSidebarModal}
        showSidebarModal={showSidebarModal}
      />
    </Wrapper>
  )
}

export default Index