
export const PlaceHolderImage = 'https://images.pexels.com/photos/1054777/pexels-photo-1054777.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'

export const BASE_URL = process.env.REACT_APP_API_URL
// export const BULKUPLOAD_SAMPLE = process.env.REACT_APP_BULKUPLOAD_SAMPLE
export const BULKUPLOAD_SAMPLE = "https://specxweb-media-public-dev.s3.us-east-2.amazonaws.com/sample/sample_1728309506903.xlsx"

export const Default_Page_Limit = 20
export const Default_Page_Number = 1
export const reading= 'reading'
export const Contact_lens = 'contact lens'
export const City_List = [
    { value: 'Indore', label: 'Indore' },
    { value: 'Bhopal', label: 'Bhopal' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Pune', label: 'Pune' },
]
export const State_List = [
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Maharastra', label: 'Maharastra' },
    { value: 'Gujrat', label: 'Gujrat' },
]
export const Store_Legal_Status = [
    { value: 1, label: 'Propritorship' },
    { value: 2, label: 'Partnership' },
    { value: 3, label: 'Company' },
]

export const limitOptions = [20, 50, 100, 200];