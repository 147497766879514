import React, { useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import moment from "moment";
import constant from "../../../../constants/constant";
import atoms from "../../../atoms";
import molecules from "../../../molecules";
import { numberWithCommas } from "../../../../constants/utils";
import { MyComponentProps, productListType } from "./interface";

const { CreateModal, ConfirmationModal,ContactLensContainer } = molecules
const { Button, Pagination, Search, Table, Select } = atoms

const Index: React.FC<MyComponentProps> = ({ transferRequestedList, setSearch, cancelButton, currentPage, setCurrentPage, setLimitPerPage, limit,isContactLensProduct,setIsContactLensProduct }) => {

    const [createModal, setCreateModal] = useState<boolean>(false)
    const [productId, setProductId] = useState<string>()
    const [cancelReason, setCancelReason] = useState<string>()
    const [cancelPopup, setCancelPopup] = useState<boolean>(false)
    const columns = [
        { name: 'Product ID', selector: (row: productListType) => row?.product?.product_id_display, minWidth: '10vw' },
        { name: 'Brand', selector: (row: productListType) => row?.product?.brand?.brand_name || '-', },
        { name: 'Cover Photo', selector: (row: productListType) => <img src={row?.product?.images[0] && row?.product?.images[0]} className='w-50 rounded object-contain' /> },
        { name: 'Model No', selector: (row: productListType) => row?.product?.modal_number || '-', },
        { name: 'Brand Color Code', selector: (row: productListType) => row?.product?.brand_color_code || '-', },
        { name: 'With Case', selector: (row: productListType) => row?.product?.product_with_case ? "Yes" : "No", },
        { name: 'Date', selector: (row: productListType) => row?.createdAt && moment(row?.createdAt).format('DD/MM/YYYY') || '-', },
        { name: 'Product Type', selector: (row: productListType) => row?.product?.product_type?.name, },
        { name: 'Glass Size/Power', selector: (row: productListType) => row?.product?.glass_size || '-', },
        { name: 'SRP', selector: (row: productListType) => `₹${numberWithCommas(parseFloat(Number(row?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: productListType) =>`₹${numberWithCommas(parseFloat(Number(row?.landing_cost)?.toFixed(2)))}` || '-', },
        { name: 'Action', selector: (row: any) => row?.action || '-', minWidth: "130px" },
    ]
    const columnsForContactLens = [
        { name: 'Product ID', selector: (row: productListType) => row?.product?.product_id_display, minWidth: '10vw' },
        { name: 'Brand', selector: (row: productListType) => row?.product?.brand?.brand_name || '-', },
        { name: 'Cover Photo', selector: (row: productListType) => <img src={row?.product?.images[0] && row?.product?.images[0]} className='w-50 rounded object-contain' /> },
        { name: 'Date', selector: (row: productListType) => row?.createdAt && moment(row?.createdAt).format('DD/MM/YYYY') || '-', },
        { name: 'Product Type', selector: (row: productListType) => row?.product?.product_type?.name, },
        { name: 'SRP', selector: (row: productListType) => `₹${numberWithCommas(parseFloat(Number(row?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: productListType) =>`₹${numberWithCommas(parseFloat(Number(row?.landing_cost)?.toFixed(2)))}` || '-', },
        { name: 'Action', selector: (row: any) => row?.action || '-', minWidth: "130px" },
    ]
    const handleModalShow = async (id: string) => {
        setProductId(id)
        setCreateModal(true)
        setCancelReason("")

    }
    const _handleClose = () => {
        setCreateModal(false)
    }

    const handleCancelResponse = () => {
        if (productId && cancelReason) {
            cancelButton(productId, cancelReason)
            _handleClose()
        }
    }

    const handleChange = (name: string, value: any) => {
        setCancelReason(value?.value)
    };

    return (
        <>
            <div className="row">
                <div className="col-md-6"><Search className="w-md-50 w-100" searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className="col-12 col-md-6 d-flex justify-content-end">
                <ContactLensContainer
                    value={isContactLensProduct}
                    onStatusChange={(value: boolean) => setIsContactLensProduct(value)}
                />
                </div>
            </div>
            <div>
                <Table
                    columns={isContactLensProduct ? columnsForContactLens : columns}
                    AdditionalClassName="table-height-50"
                    data={transferRequestedList?.data?.map((item: any) => {
                        return ({
                            ...item,
                            action:
                                <div style={{ overflow: 'unset', textOverflow: 'unset' }}>
                                    {!item?.order?.order_id_display &&
                                        <Button className="table-edit-buttons fs-14 fw-500">
                                            <div className='d-flex align-items-center'
                                                onClick={() => handleModalShow(item?.id)}
                                            >
                                                <RxCrossCircled className='fs-14 fw-400' />
                                                <span className='ms-2 mb-0'>Cancel</span>
                                            </div>
                                        </Button>}
                                </div>

                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={transferRequestedList?.count}
                    currentPage={currentPage}
                    totalPages={transferRequestedList?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>

            {createModal && <CreateModal
                show={createModal}
                header={`Cancel Pickup`}
                // handleSubmit={handleCancelResponse}
                handleSubmit={() => { setCancelPopup(!cancelPopup) }}
                submitBtnText={`Confirm`}
                onHide={() => _handleClose()}
            >
                <div>
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <Select label={"Reason"}
                                    className={"w-100"}
                                    options={constant.ProductCancel}
                                    onChange={(e: any) => { handleChange("store_id", e) }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </CreateModal>}
            {cancelPopup && <ConfirmationModal
                show={cancelPopup}
                handleConfirmItem={() => { handleCancelResponse(); setCancelPopup(!cancelPopup) }}
                onHide={() => setCancelPopup(!cancelPopup)}
                message={'cancel this request'} />}
        </>
    )
}

export default Index