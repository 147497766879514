const Staff = {
    staff: () => 'store/staff',
    staffById: (id: string) => `store/staff/${id}`,
    changeStaffPassword:(id:string)=>`store/staff/change-password/${id}`,
    structuredPermission : () => `admin/structured-permissions`,

    // for subAdmin
    subadmin: () => 'store/subadmin',
    subAdminById:(id:string)=>`store/subadmin/${id}`
}
export default Staff